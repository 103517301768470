<template>
  <header>
    <div class="img-container">
      <img :src="require(`@/assets/${sampleList[0].img}`)" :alt="sampleList[0].text">
      <div class="containerTip">
        <div class="insLoge"></div>
        <!-- <router-link to="./home" class="insLoge"></router-link> -->
        <nav>
          <ul class="no-bullets">
            <li v-for="item, index in jumpList" :key="index" class="isLink" @click="handleNavigation(item)">
              {{ item.text }}
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </header>

  <section v-for="item, index in filteredList" :key="index" class="container-dwn">
    <div class="image-wrapper">
      <img :src="require(`@/assets/${item.img}`)" :alt="item.text">
      <!-- <img v-if="item.id == 4" src="../assets/downloadBt.svg" alt="下载按钮" class="downloadbtn" @click="handleDown"> -->
    </div>
  </section>

  <footer>
    <img :src="require(`@/assets/${sampleList[4].img}`)" :alt="sampleList[4].text" class="footer-img">
    <div class="text-over-img">
      <nav>
        <ul class="no-bullets">
          <li v-for="item, index in jumpList" :key="index" class="isLink" @click="handleNavigation(item)">
            {{ item.name }}
          </li>
        </ul>
      </nav>
    </div>
  </footer>
</template> 

<script>

export default {
  data() {
    return {
      // 页签
      sampleList: [
        {
          id: '1',
          img: 'image1.svg',
          text: 'Exhibit',
        },
        {
          id: '2',
          img: 'image2.svg',
          text: 'Exhibit2',
        },
        {
          id: '3',
          img: 'image3.svg',
          text: 'Exhibit',
        },
        {
          id: '4',
          img: 'image4.svg',
          text: 'Exhibit',
        },
        {
          id: '5',
          img: 'image5.svg',
          text: 'Exhibit',
        },
      ],

      jumpList: [
        {
          id: '1',
          text: 'Privacy Policy',
          name: 'Privacy Policy',
        },
        {
          id: '2',
          text: 'Service of Use',
          name: 'Service of Use',
        },
      ],
    };
  },
  computed: {
    filteredList() {
      return this.sampleList.filter((item, index) => index !== 0 && index !== this.sampleList.length - 1);
    },
  },
  methods: {
    handleNavigation(item) {
      if (item.id === '1') {
        this.$router.push('/privacy-policy');
      } else if (item.id === '2') {
        this.$router.push('/service-use');
      }
      // 可以根据具体需要扩展其他页面跳转逻辑
    },
    handleDown() {
      // window.open("http://www.wlzhys.com", "_blank")
    }
  }
};
</script>

<style scoped>
img {
  width: 100%;
}

.img-container {
  position: relative;

  .containerTip {
    position: absolute;
    width: 100%;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: space-between;
    padding: 0 7%;
    align-items: center;

    .insLoge {
      color: #FFF;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: PingFang SC;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      text-decoration: auto;
    }

    .no-bullets {
      list-style-type: none;
      display: flex;
      align-items: center;
      margin-bottom: 0%;

      .isLink {
        color: #BBB;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: PingFang SC;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 100% */
        letter-spacing: 0.15px;
      }

      .isLink:hover {
        cursor: pointer;
        color: #fff;
      }

      .isLink:nth-child(2) {
        margin-left: 50px;
      }
    }
  }
}

.container-dwn {
  position: relative;

  .image-wrapper {
    position: relative;
    display: inline-block;

    .downloadbtn {
      position: absolute;
      top: 69%;
      left: 20%;
      transform: translate(-50%, -50%);
      width: 16%;
    }

    .downloadbtn:hover {
      cursor: pointer;
    }
  }
}

footer {
  position: relative;

  .footer-img {
    position: relative;
    display: block;
    max-width: 100%;
    height: auto;
  }

  .text-over-img {
    position: absolute;
    top: 60%;
    left: 19.3%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-weight: bold;

    .no-bullets {
      list-style-type: none;
      display: flex;
      align-items: center;
      margin-bottom: 0%;

      .isLink {
        color: #FFF;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: PingFang SC;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 100% */
        letter-spacing: 0.15px;
      }

      .isLink:hover {
        cursor: pointer;
        color: #fff;
      }

      .isLink:nth-child(1) {
        margin-right: 50px;
      }
    }
  }
}
</style>
