<template>
    <header>
        <img src="../assets/top.svg" alt="" style="width: 100%;">
        <div class="containerTip">
            <router-link to="./home" class="ins insLoge">Proxy VPN</router-link>
            <router-link to="./home" class="ins">home</router-link>
        </div>
    </header>

    <section>
        <div class="container" style="margin-bottom: 40px;">
            <div class="row justify-content-center">
                <div class="col-lg-6">
                    <h2 class="h2ss">Terms of Services</h2>
                </div>
            </div>
            <div>
                <p class="mt-10 isContent">
                    This is an agreement between you and MobileJump for virtual private network communications services, related services and features. It is NOT a service for the commission of criminal acts. It is an anonymity and privacy service. You agree not to violate any laws of any jurisdiction you are originating from, or terminating into.
                </p>
                <p class="mt-10 isContent">Please carefully read all the terms and conditions of MobileJump. When you click to install MobileJump applications, you are agreeing with all the terms and conditions of the service. And if you don't agree,you can cancel the installation and you must destroy,return or delete all copies of MobileJump applications in your possession. </p>
                <p class="mt-10 isContent">
                    You agree not to use the system for sending spam, port scanning, scanning for open proxies or open relays, sending opt-in email, unsolicited email or any type or version of email sent in large quantities even if the email is ultimately sent off of another server. You agree not to launch any pop-ups from our service. You agree not to attack in any way shape or form any other computer or network while on our service. </p>  
                <p class="mt-10 isContent">Actual service coverage, speeds, locations and quality may vary. The Service will attempt to be available at all times except for limited periods for maintenance and repair. However the Service may be subject to unavailability for a variety of factors beyond our control including emergencies, third party service failures, transmission, equipment or network problems or limitations, interference, signal strength, and may be interrupted, refused, limited or curtailed. We are not responsible for data, messages or pages lost, not delivered, delayed or misdirected because of interruptions or performance issues with the Service or communications services or networks (e.g., T-1 lines or the Internet). We may impose usage or Service limits, suspend Service, or block certain kinds of usage in our sole discretion to protect users or the Service. Network speed is an estimate and is no indication of the speed at which your or the Service sends or receives data. Actual network speed will vary based on configuration, compression, network congestion and other factors. The accuracy and timeliness of data received is not guaranteed; delays or omissions may occur.</p>
                <p class="mt-10 isContent">Hacking, cracking, distribution of viruses, fraudulent activities, network sabotage, phishing, and/or any conduct deemed illegal or unwanted shall be subject to suspension or termination. Users are responsible for the proper secure configuration of their services and are responsible for any damages caused by their neglect or exposure of vulnerabilities whether intentional or unintentional.</p>
                <p class="mt-10 isContent">We do not put any cap on bandwidth or data usage per user however the use of service is subjected to fair usage. Creating automated or manual VPN sessions in a manner that would impersonate a bot or exerting excessive load on the network that may disturb other users on the network or utilizing substantially excessive bandwidth that exceeds the average user bandwidth usage for an extended duration or the likes, all are considered as unfair usage of the network. We reserve the right to temporary suspend or permanently terminate users found involved in unfair usage of its services.</p>
                <h3 class="mt-20 mb-10 titles">👉1、Third Party Services</h3> 
                <p class="mt-10 isContent">For a better experience, while using our Service, I may require you to provide us with certain personally identifiable information. The information that I request will be retained on your device and is not collected by me in any way.</p>
                <h3 class="mt-20 mb-10 titles">👉2、Disclaimer of Certain Liabilities</h3> 
                <p class="mt-10 isContent">The information available via the App may include typographical errors or inaccuracies. Proxy VPN Studio shall not be liable for these inaccuracies and errors.</p>
                <p class="mt-10 isContent">
                    Proxy VPN Studio makes no representations about the availability, accuracy, reliability, suitability, and timeliness of the Content contained on and services available on the App. To the maximum extent allowed by the applicable law, all such Content and services are provided on the "as is" basis. Proxy VPN Studio disclaims all warranties and conditions regarding this Content and services, including warranties and provisions of merchantability, fitness for a certain purpose.
                </p>
                <p class="mt-10 isContent">
                    The VPN application of Proxy VPN Studio does not interfere with each user's right of use. Creating automated or manual VPN sessions in a manner that would impersonate a bot or exerting excessive load on the network that may disturb other users on the network or utilizing substantially excessive bandwidth that exceeds the average user bandwidth usage for an extended duration or the likes, all are considered as unfair usage of the network.  Proxy VPN Studio reserves the right to temporary suspend or permanently terminate users found involved in unfair usage of its services.
                </p>
                <h3 class="mt-20 mb-10 titles">👉3、Termination and Access Restriction</h3> 
                <p class="mt-10 isContent">Proxy VPN Studio may terminate your access and account to the App and its related services or any part at any time, without notice, in case of your violation of the Terms.</p>
                <h3 class="mt-20 mb-10 titles">👉4、Changes to this Terms & Conditions</h3> 
                <p class="mt-10 isContent">
                    For the purpose to consist with applicable Service Agreement laws and principles, this Service Agreement may be modified from time to time without prior notice to you, so please review it frequently. Your continued use of our VPN services constitutes your acceptance of our Terms & Conditions.
                </p>
                <p class="mt-10 isContent">
                    Last updated Dec 20, 2023
                </p>
            </div>
        </div>
    </section>
</template>
  
<script>
export default {
    // 组件逻辑
}
</script>

<style scoped>
header {
    position: relative;

    .containerTip {
        position: absolute;
        width: 100%;
        top: 50%;
        display: flex;
        justify-content: space-between;
        padding: 0 7%;

        .ins {
            color: #FFF;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: PingFang SC;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 32px;
            text-decoration: auto;
        }

        .insLoge {
            font-size: 48px;
        }
    }
}

section {
    .isColor {
        color: #000;
        font-weight: 600;
    }

    .isLink {
        color: #2a73f7;
    }

    .col-lg-6 {
        margin: 60px 0 80px 0;

        .h2ss {
            font-size: 64px;
            color: #333;
            font-weight: 500;
            text-align: center;
        }
    }

    .mb-10 {
        margin-bottom: 10px;
    }

    .mt-20 {
        margin-top: 20px;
    }

    p {
        text-indent: 25px;
    }

    .titles {
        color: #333;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: PingFang SC;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 42px;
    }

    .isContent {
        color: #404040;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: PingFang SC;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
    }
}
</style>