<template>
    <header>
        <img src="../assets/top.svg" alt="" style="width: 100%;">
        <div class="containerTip">
            <router-link to="./home" class="ins insLoge">Proxy VPN</router-link>
            <router-link to="./home" class="ins">home</router-link>
        </div>
    </header>

    <section>
        <div class="container" style="margin-bottom: 40px;">
            <div class="row justify-content-center">
                <div class="col-lg-6">
                    <h2 class="h2ss">PRIVACY POLICY</h2>
                </div>
            </div>
            <div>
                <h3 class="mt-20 mb-10 titles">👋 Welcome
                </h3>
                <p class="mt-10 isContent">
                    Security and privacy are at the heart of what we do. Accordingly, we think it’s important to be transparent about what we do with your information and how we handle it.  This Privacy Policy describes how we process and handle data provided to us in connection with your use of our products, services, apps, and websites that link to this policy (we refer to these collectively as our “services”).
                </p> 
                <p class="mt-10 isContent">
                    This policy uses the term “personal data” to refer to information that is related to an identified or identifiable natural person and is protected as personal data under applicable data protection law.
                </p>
                <h3 class="mt-20 mb-10 titles">👉1.Who are we?
                </h3>
                <p class="mt-10 isContent">
                    This policy covers the data processing practices of Goodapps Studio Inc.
                </p>

                <p class="mt-10 isContent">
                    In this policy, “we,” “us,” and “our” refers to the particular company from the list above that provides you with the services and is responsible for handling your data in accordance with this policy. For mobile apps, you can identify which company this is by looking at which company is listed as offering the app on the download page for that app or reviewing its terms of service

                </p>
                <h3 class="mt-20 mb-10 titles">👉2.What information do we collect about you?
                </h3>
                <p class="mt-10 isContent">
                    This section describes the various types of information we collect from and about you. This information is not collected in all situations, but only in specific situations. To understand the context in which collection occurs, see Section 2 (How do we use your information?). More information about some of the mechanisms we use to collect this information, such as cookies, is available in Section 4 (Tracking Technologies & Cookies).</p>
                <p class="mt-10 isContent">(a) Information you provide to us</p>
                <p class="mt-10 isContent">● Account information. Some services require or allow you to create an account before you can access them. As part of registering for an account, we may collect information such as your name, username, email address, and password. </p>
                <p class="mt-10 isContent">● Billing and payment information. In order to purchase a service, you may need to provide to us with details such as billing name, billing contact details (street addresses, email addresses), and payment instrument details.</p>
                <p class="mt-10 isContent">● Identity verification information. Some services require you to verify your identity as part of creating an account to access them. We may collect information such as email addresses for this purpose. </P>
                <p class="mt-10 isContent">● Communications and submissions. You may choose to provide us with information when you communicate with us (e.g. via email for support or to inquire about our services), including when you fill out an online form, respond to surveys, provide feedback, participate in promotions, or submit information through our services. </p>
                <p class="mt-10 isContent">(b) Information collected when you use our services</p>
                <p class="mt-10 isContent">● Usage information. We collect information about how you interact with our services, such as how much bandwidth you use, and when and for how long you use our services.</p>
                <p class="mt-10 isContent">● Device information. We collect information from and about the device you use to access our services. For example, we may collect device identifiers, browser types, device types and settings, operating system versions, mobile, wireless, and other network information (such as internet service provider name, carrier name and signal strength), and application version numbers.</p>
                <p class="mt-10 isContent">● Diagnostic information. We may collect information about the nature of the requests that you make to our servers (such as what is being requested, information about the device and app used to make the request, timestamps, and referring URLs). However, we do not log any information that associates your identity with your VPN browsing activity. We do not maintain any records that show what you were browsing or accessing through a VPN connection.</p>
                <p class="mt-10 isContent">● Location information. We may collect your location information based on a variety of sources, such as your device’s GPS or by calculating an approximate latitude and longitude based on your IP address.</p>
                <p class="mt-10 isContent">(c) Information provided to us by third parties</p>
                <p class="mt-10 isContent">● Referrals. If you are invited to use our service, the person who invited you may submit your personal data, such as your email address or other contact information.</p>
                <p class="mt-10 isContent">● Third Party Accounts. Some services may allow you to register an account using a third party account (such as a Google or Microsoft account). If you do so, that third party may send us some information about you that they have. You may be able to control what information they send us via your privacy settings for that third party account.</p>
                <p class="mt-10 isContent">● Threat Information. We may receive information from reputable members of the security industry who provide information to help us to provide, develop, test, and improve our services (for example, lists of malicious URLs, spam blacklists, phone number blacklists, and sample malware). Some of this information may contain personal data on an incidental basis.</p>
                <p class="mt-10 isContent">You generally do not have a duty to disclose personal data to us unless you have a contractual obligation to us to do so. However, we need to collect and process certain information that is necessary or legally required in order to provide the services to you or otherwise perform our contractual relationships with you.</p>
                <p class="mt-10 isContent">We use the information we collect for various purposes described below.</p>
                <p class="mt-10 isContent">● To provide, maintain, troubleshoot, and support our services. We use your information for this purpose on the basis that it is required to fulfill our contractual obligations to you. Examples: using information about how much bandwidth you use and how long you use our services in order to provide the services in accordance
                 with a plan to which you have subscribed; using threat and device information to determine whether certain items pose a potential security threat; using location information to connect you to the nearest and fastest VPN server; and using usage information to troubleshoot a problem you report with our services and to ensure the proper functioning of our services. </p>
                <p class="mt-10 isContent">● For billing and payment purposes. We use your information in order to perform billing administration activities and process payments, which are required to fulfill our contractual obligations.</p>
                <p class="mt-10 isContent">● To communicate with users and prospective users. We use your information to communicate with you, including by responding to your requests, and sending you information and updates about our services. We may do this in order to fulfill our contract with you, because you consented to the communication, or because we have a legitimate interest in providing you with information about our services.</p>
                <p class="mt-10 isContent">● To improve our services. We want to offer you the best services and user experiences we can, so we have a legitimate interest in continually improving and optimizing our services. To do so, we use your information to understand how users interact with our services. Examples: we analyze certain usage, device, and diagnostic information to understand aggregated usage trends and user engagement with our services (and, for example, invest in technical infrastructure to better serve regions with increasing user demand); we may use device and threat information to conduct spam, threat, and other scientific research to improve our threat detection capabilities; we review customer feedback to understand what we could be doing better.</p>
                <p class="mt-10 isContent">● To develop new services. We have a legitimate interest in using your information to plan for and develop new services. For example, we may use customer feedback to understand what new services users may want. </p>
                <p class="mt-10 isContent">● To market and advertise our services. We may use your information to provide, measure, personalize, and enhance our advertising and marketing based on our legitimate interest in offering you services that may be of interest. Examples: we may use information such as who or what referred you to our services to understand how effective our advertising is; we may use information to administer promotional activities such as sweepstakes and referral programs. Our VPN products do not use your VPN browsing activity for these purposes and we do not maintain any records that show what you were browsing or accessing through a VPN connection.</p>
                <p class="mt-10 isContent">● To prevent harm or liability. We may use information for security purposes (such as to investigate security issues or to monitor and prevent fraud) and to prevent abuse. We may do this to comply with our legal obligations, to protect an individual’s vital interests, or because we have a legitimate interest in preventing harm or liability to us and our users. For example, we may use account, usage, and device information to determine if an entity is engaging in abusive or unauthorized activity in connection with our services.</p>
                <p class="mt-10 isContent">● For legal compliance. We internally use your information as required by applicable law, legal process, or regulation. To learn about our practices regarding sharing your information with third parties for legal compliance purposes, see Section 3.1 below. We also use your information to enforce our legal rights and resolve disputes.</p>
                <h3 class="mt-20 mb-10 titles">👉3.Who do we share your information with and why?</h3>
                <p class="mt-10 isContent">(a) In General</p>
                <p class="mt-10 isContent">We may disclose your information in the following circumstances:</p>
                <p class="mt-10 isContent">● In accordance with your instructions or consent. For example, some services may allow you to register an account using a third party account (such as a Google or Microsoft account). If you choose to do so, we will share information with the third party account provider.</p>
                <p class="mt-10 isContent">Affiliates and third party service providers. To help us provide some aspects of our services, we work with our affiliates and trusted third parties. To protect your data, we enter into appropriate confidentiality and data processing terms with these third parties, review their security practices, and limit information sharing to the scope of what they are helping us with. Examples of activities that third parties help us with include:</p>
                <p class="mt-10 isContent">○ processing customer payments</p>
                <p class="mt-10 isContent">○ providing analytics about our services</p>
                <p class="mt-10 isContent">○ providing sales and customer support</p>
                <p class="mt-10 isContent">○ maintaining the infrastructure required to provide our services</p>
                <p class="mt-10 isContent">○ delivering our marketing and advertising content</p>
                <p class="mt-10 isContent">○ serving advertising content</p>
                <p class="mt-10 isContent">● To a new owner. If ownership or control of all or part of our services, assets, or business changes, we may transfer your information to the new owner.</p>
                <p class="mt-10 isContent">● Aggregated or de-identified data. We may use and share aggregated data and data that is de-identified such that it no longer reveals the identity of an individual user for regulatory compliance, research and analysis, our own marketing and advertising activities and other legitimate business purposes.</p>
                <p class="mt-10 isContent">● To comply with legal process and the law. We are fiercely protective of the privacy of our users. For our VPN products, we protect your privacy by ensuring that we do not log or record online activities that you conduct over a VPN connection in any way that can be tied back to you, meaning that we do not have any data to share with law enforcement and government agencies who make requests for information about what you were doing through a VPN connection. Subject to the foregoing, we may share your information if we are required to do so by applicable law; to comply with our legal obligations; to comply with legal process; and to respond to valid law enforcement requests relating to a criminal investigation, or alleged or suspected illegal activity that may expose us, you, or any of our other users to legal liability. If we share your information for these purposes, we limit the information shared to what is legally necessary, and challenge information requests that we believe are unlawful, overbroad, or otherwise invalid.</p>
                <p class="mt-10 isContent">● To enforce our rights and prevent fraud and abuse. We may share limited amounts of your information to enforce and administer our agreements with customers and users, and to respond to claims asserted against us. We may also share your information in order to protect against fraud and abuse against us, our affiliates, users and others.</p>
                <p class="mt-10 isContent">(b) Displaying Advertisements</p>
                <p class="mt-10 isContent">We may serve ads to users in certain regions. The ads we display in our services are supplied either by advertisers we have relationships with or by third party advertising networks such as Google. To display third party ads in our apps, we integrate into them a software development kit (SDK), which consists of software code provided by the ad network.</p>
                <p class="mt-10 isContent">We provide limited personal data about you to such third party advertising networks to enable them to provide their services. For example, some of these advertising networks may collect information through their SDKs, such as your mobile advertising identifier, IP address, and device information, for the purpose of serving you with “personalized” ads (ads that they think are more relevant to you) and measuring your response to those ads. If you are using a VPN connection, your IP address is hidden from ad networks and replaced with the IP address of our VPN servers.</p>
                <p class="mt-10 isContent">Each advertising network collects this information according to their privacy policies. Where an AdChoices logo appears on an ad, you can click it to learn more about the ad network that provided the ad, its privacy policy, and your choices regarding opting out from any personalized advertising. If you opt out from personalized advertising, you may still see non-personalized ads.</p>
                <p class="mt-10 isContent">While we request you not to use ad blockers to prevent the display of third party ads because that is how we support our free services, our services are able to continue functioning if you do use ad blockers.</p>
                <h3 class="mt-20 mb-10 titles">👉4.Tracking Technologies & Cookies</h3>
                <p class="mt-10 isContent">(a) About Tracking Technologies</p>
                <p class="mt-10 isContent">We use various technologies in our services to help us collect information. For convenience, we refer to these as “tracking technologies,” although they are not always used to track individuals and the information collected is in a non-identifiable form that does not reference any personal data. Tracking technologies include:</p>
                <p class="mt-10 isContent">Cookies</p>
                <p class="mt-10 isContent">Cookies are small portions of text that are stored on the device you use to access our services. Cookies enable us (or third parties that we allow to set cookies on your device) to recognize repeat users. Cookies may expire after a period of time, depending on what they are used for.</p>
                <p class="mt-10 isContent">Pixel Tags / Page Tags / Web Beacons / Tracking Links</p>
                <p class="mt-10 isContent">These are small, hidden images and blocks of code placed in web pages, ads, and our emails that allow us to determine if you perform a specific action. When you access a page, ad, or email, or click a link, these items let us know that you have accessed that page, opened an email, or clicked a link.</p>
                <p class="mt-10 isContent">SDKs</p>
                <p class="mt-10 isContent">SDKs or software development kits are software code provided by our business partners that let our software interact with the services those partners provide. For example, in certain of our mobile apps, we may use an SDK to enable our app to serve ads from an advertising network. Sometimes these interactions will involve that business partner collecting some information from the device on which the software is run.</p>
                <p class="mt-10 isContent">(b)4.2. Why we use Cookies</p>
                <p class="mt-10 isContent">● To provide our services. Some cookies are essential for the proper operation of our services. For example, cookies allow us to authenticate who you are and whether you’re authorized to access a resource.</p>
                <p class="mt-10 isContent">● To store your preferences. Cookies can store your preferences, such as language preferences or whether to pre-fill your username on sign in forms. We may also use them to optimize the content that we show to you.</p>
                <p class="mt-10 isContent">● For analytics. Cookies are used to inform us how users interact with our services so we can, as a legitimate interest, improve how they work (such as what screens or webpages you access, and whether our advertising is effective).</p>
                <p class="mt-10 isContent">● For security. Cookies can enable us and our payment processors to detect certain kinds of fraud</p>
                <p class="mt-10 isContent">● For advertising-related purposes. We advertise our services online with the help of third parties who show ads and marketing about us on sites around the internet.</p>
                <p class="mt-10 isContent">(c)4.3. Third Parties and Advertising</p>
                <p class="mt-10 isContent">We may allow our business partners to place certain tracking technologies in our services. These partners use these technologies for the following purposes:</p>
                <p class="mt-10 isContent">● To provide our services. Some business partners who help us to provide our services may use these technologies to support those efforts.</p>
                <p class="mt-10 isContent">● For Analytics. To help us understand how you use our services.</p>
                <p class="mt-10 isContent">● For Marketing. To help us market and advertise our services to you, including on third party websites. Cookies are used in connection with this to measure the performance of our advertising, attribute actions you take with our ads with actions you take on our services, deliver ad retargeting (serving ads based on your past interactions with our services), and target ads at similar audiences.</p>
                <p class="mt-10 isContent">● To Serve Ads. Ad networks may use these technologies to display ads which they think will be more relevant to you. For more information, please see the “Displaying Advertisements” section above..</p>
                <p class="mt-10 isContent">● We do not server any ads for the VIP subscription of our services. As well, we are proud to offer free, advertising-supported VPN services to the customers. We work with third-party advertising partners to show ads to the customers who choose free VPN services.</p>
                <p class="mt-10 isContent">● We ensure that we do not target any personal information based on these ads. Although you opt in to use the free, advertising-supported VPN services, we won't share the advertisers any your personal information or any usage information without your prior consent.</p>
                <p class="mt-10 isContent">● Notwithstanding the foregoing, these advertising partners may set and access their own cookies, pixel tags, and similar technologies on our services, and they may otherwise collect or have access to information about you which they may collect over time and across different online services.</p>
                <p class="mt-10 isContent">● To learn more about these programs, please visit:</p>
                <p class="mt-10 isContent">Google Play Services: https://policies.google.com/privacy</p>
                <p class="mt-10 isContent">AdMob: https://policies.google.com/privacy</p>
                <p class="mt-10 isContent">Google Analytics for Firebase: https://firebase.google.com/policies/analytics</p>
                <p class="mt-10 isContent">Pangle AD: https://www.pangleglobal.com/privacy</p>
                <p class="mt-10 isContent">Facebook: https://www.facebook.com/about/privacy/update/printable</p>
                <p class="mt-10 isContent">AppLovin:https://www.applovin.com/privacy/</p>
                <p class="mt-10 isContent">Google Firebase: https://policies.google.com/privacy/partners</p>
                <p class="mt-10 isContent">Facebook Analytics:https://www.facebook.com/about/privacy/updatee</p>
                <p class="mt-10 isContent">(d)4.4. Your Choices</p>
                <p class="mt-10 isContent">● Our Cookies: Most web browsers and some mobile devices give you the ability to manage your cookie preferences, including deleting cookies and blocking cookies from being set on those browsers or devices. Visit the “help” section of your mobile device to understand what controls it gives you over cookies. Note that deleting or blocking certain cookies could adversely impact the proper operation of our services.</p>
                <p class="mt-10 isContent">● Third Party Advertising Cookies: For information on how to opt out of personalized or interest-based advertising, you can visit the following pages:</p>
                <p class="mt-10 isContent">○ Network Advertising Initiative opt out page</p>
                <p class="mt-10 isContent">○ Digital Advertising Alliance opt out page</p>
                <p class="mt-10 isContent">○ Your Online Choices (for Australian residents)</p>
                <p class="mt-10 isContent">○ Your Online Choices (for EU residents)</p>
                <p class="mt-10 isContent">To opt out from Google ad personalization, visit the Google Ads Settings page. These opt out mechanisms are not provided by us and we are not responsible for the availability or operation of them. Note that after opting out of personalized advertising you may still see non-personalized ads.</p>
                <p class="mt-10 isContent">● Google Analytics: We use Google Analytics to help us understand how users use our services. Google makes available a Google Analytics Opt Out Browser Add-On if you do not want to participate in Google Analytics.</p>
                <h3 class="mt-20 mb-10 titles">👉5.Security
                </h3>
                <p class="mt-10 isContent">We employ a range of administrative, organizational, technical, and physical safeguards designed to protect your data against unauthorized access, loss, or modification. We continuously work to improve such safeguards.</p>
                <h3 class="mt-20 mb-10 titles">👉6.International Data Transfers
                </h3>
                <p class="mt-10 isContent">
                We may transfer your personal data to countries other than the one in which you reside. We do this to facilitate our operations, and transferees include our group companies, service providers, and partners. Laws in other countries may be different to those that apply where you reside. For example, personal data collected within Switzerland, United Kingdom or the European Economic Area (EEA) may be transferred and processed outside Switzerland, United Kingdom or the EEA for purposes described in this policy. However, we put in place appropriate safeguards that help to ensure that such data receives an adequate level of protection. You may contact us if you would like more information about such safeguards.</p>
                <h3 class="mt-20 mb-10 titles">👉7.Data Retention </h3>
                <p class="mt-10 isContent">We generally retain your personal data for as long as is needed to provide the services to you, or for as long as you have an account with us. We may also retain personal data if required by law, or for our legitimate interests, such as abuse detection and prevention, and defending ourselves from legal claims. Residual copies of personal data may be stored in backup systems as a security measure to protect against data loss. Subject to the foregoing, where your personal data is no longer required, we will ensure it is either securely deleted or stored in a way that means it will no longer be usable by us.</p>
                <h3 class="mt-20 mb-10 titles">👉8.Your Rights </h3>
                <p class="mt-10 isContent">Depending on your country of residence, you may have certain legal rights in relation to your personal data that we maintain. Subject to exceptions and limitations provided by applicable law, these may include the right to:</p>
                <p class="mt-10 isContent">● access and receive a copy of your personal data;</p>
                <p class="mt-10 isContent">● correct your personal data;</p>
                <p class="mt-10 isContent">● restrict the processing of your personal data;</p>
                <p class="mt-10 isContent">● object at any time to the processing of your personal data;</p>
                <p class="mt-10 isContent">● have your personal data erased;</p>
                <p class="mt-10 isContent">● data portability;</p>
                <p class="mt-10 isContent">● withdraw any consent you previously gave to the processing of your data (such as opting out to marketing communications);</p>
                <p class="mt-10 isContent">● lodge a complaint with a data protection authority;</p>
                <p class="mt-10 isContent">● request that we provide you with the categories of personal data we collect, disclose or sell about you; the categories of sources of such information; the business or commercial purpose for collecting or selling your personal data; and the categories of third parties with whom we share personal data. This information may also be provided in this Privacy Policy.</p>
                <p class="mt-10 isContent">Please note your rights and choices vary depending upon your location, and some information may be exempt from certain requests under applicable law.</p>
                <p class="mt-10 isContent">You may be able to exercise some of these rights by using the settings and tools provided in our services. For example, you may be able to update your user account details via the relevant account settings screen of our apps. You may also be able to opt out from receiving marketing communications from us by clicking an “opt out” or “unsubscribe” link in such communications.</p>
                <p class="mt-10 isContent">Otherwise, if you wish to exercise any of these rights, you may contact us using the details in the “Contact Us” section below. As permitted by law, we may ask you to verify your identity before taking further action on your request.</p>
                <h3 class="mt-20 mb-10 titles">👉9. Your California Privacy Rights  
                </h3>
                <p class="mt-10 isContent">(a) 9.1. Do Not Track Signals</p>
                <p class="mt-10 isContent">We currently do not respond to “Do Not Track” signals sent by browsers or mobile apps due to a lack of standardization regarding how that signal should be interpreted. Learn more about Do Not Track.</p>
                <p class="mt-10 isContent">(b)9.2. Third Party Disclosure Requests</p>
                <p class="mt-10 isContent">California law permits users who are California residents to request and obtain from us once a year, free of charge, a list of the third parties to whom we have disclosed their “personal information” (if any) as defined under California Civil Code 1798.83 for their direct marketing purposes in the prior calendar year, as well as the type of personal information disclosed to those parties. See the “Contact Us” section below for where to send such requests. Note that we do not share personal information with third parties for their own direct marketing purposes without your prior consent.</p>
                <p class="mt-10 isContent">(c)9.3. Opt-Out From Sale</p>
                <p class="mt-10 isContent">You may have the right to opt out of the “sale” of your personal information as defined by the California Consumer Privacy Act (CCPA). The CCPA broadly defines “sale” such that it may include allowing third parties to receive certain information, e.g. cookies, to deliver personalized advertising on our services.</p>
                <p class="mt-10 isContent">We do not “sell” (as defined by the CCPA) your personal information except in the context of certain versions of our products that are supported by personalized advertising. Advertising, including personalized advertising, enables us to provide certain of our products for free, to provide offers relevant to you, and, because we believe it’s important that everyone has the opportunity, regardless of their situation, to have secure and private access to the internet. Personalized advertising enables ads to be shown that are more relevant to you.</p>
                <p class="mt-10 isContent">Depending on the services you use and how you use them, we may “sell” (as defined by California law) the following categories of personal information for such advertising purposes:</p>
                <p class="mt-10 isContent">● Identifiers (such as advertising identifiers and cookies)</p>
                <p class="mt-10 isContent">● Internet or other network or device activity (such as which of our apps you’re using)</p>
                <p class="mt-10 isContent">● Approximate geolocation information</p>
                <p class="mt-10 isContent">To opt out of our use of your information for such purposes, visit: Do Not Sell My Personal Information. Please note that we do not knowingly sell the personal information of minors under 16 years of age without legally-required affirmative authorization.</p>
                <p class="mt-10 isContent">(d)9.4 Additional Rights</p>
                <p class="mt-10 isContent">California law may permit you to request that we:</p>
                <p class="mt-10 isContent">● provide you the categories of personal information we have collected or disclosed about you in the last twelve months, the categories of sources of such information, the business or commercial purpose for collecting or selling your personal information, and the categories of third parties with whom we shared personal information. This information is provided in this Notice;</p>
                <p class="mt-10 isContent">● provide access to, or a copy, of certain information we hold about you;</p>
                <p class="mt-10 isContent">● delete certain information we have about you.</p>
                <p class="mt-10 isContent">You may also have the right to receive information about the financial incentives that we offer to you (if any), and the right to not be discriminated against (as provided for in the CCPA) for exercising certain of your rights.</p>
                <p class="mt-10 isContent">Certain information may be exempt from such requests under applicable law. For example, we need certain types of information so that we can provide the services to you and for compliance with applicable law.</p>
                <p class="mt-10 isContent">When you exercise certain rights, we may take reasonable steps to authenticate your identity before fulfilling your request (such as verifying your email address or payment instrument). If you ask us to delete certain information, you may no longer be able to access or use our services.</p>
                <p class="mt-10 isContent">You are also permitted to designate an authorized agent to submit certain requests on your behalf. In order for an authorized agent to be verified, you must provide the authorized agent with a signed, written permission to make such requests, or a power of attorney. We may also follow up with you to verify your identity before processing the authorized agent’s request.</p>
                <p class="mt-10 isContent">If you would like further information regarding your legal rights under California law or would like to exercise any of them, please contact us at the address listed under the Contact Us section below.</p>
                <p class="mt-10 isContent">(e)9.5. Summary of How we Handle your Personal Information</p>
                <p class="mt-10 isContent">The California Consumer Privacy Act (CCPA) requires that we make certain disclosures to California residents, including the categories of personal information we collect, the purposes for which we use that information, the categories of sources of personal information, and the categories of third parties with whom we share personal information. This section lays out this information in the manner specified by the CCPA, but it does not differ in substance from the information in the rest of this policy.</p>
                <p class="mt-10 isContent">Sources of Personal Information</p>
                <p class="mt-10 isContent">All the categories of personal information we collect come from the following categories of sources:</p>
                <p class="mt-10 isContent">● You (either via voluntary submission or automatic collection, such as when you use our services)</p>
                <p class="mt-10 isContent">● Third parties (such as service providers, other users or marketing partners who may refer you to our services)</p>
                <p class="mt-10 isContent">Categories of Personal Information Collected and with Whom we Share It</p>
                <p class="mt-10 isContent">● Identifiers (such as name, account username, email address)</p>
                <p class="mt-10 isContent">● Commercial information (such as transaction data)</p>
                <p class="mt-10 isContent">● Financial and transactional data (such as payment instrument details)</p>
                <p class="mt-10 isContent">● Internet or other network or device activity (such as app usage and interactions with our services)</p>
                <p class="mt-10 isContent">● Geolocation information (e.g., your city and state based on IP address)</p>
                <p class="mt-10 isContent">● Demographics and statistical information (such as age and gender)</p>
                <p class="mt-10 isContent">● Customer service information (e.g. support requests)</p>
                <p class="mt-10 isContent">● Survey responses and other research information</p>
                <p class="mt-10 isContent">● Other information that identifies or can be reasonably associated with you</p>
                <p class="mt-10 isContent">Categories of Third Parties with Whom we Share Your Personal Information</p>
                <p class="mt-10 isContent">The categories of personal information collected, as described above, may be shared with the following categories of third parties:</p>
                <p class="mt-10 isContent">● Our affiliates</p>
                <p class="mt-10 isContent">● Third party service providers that perform services on our behalf (e.g. payment processors, analytics providers)</p>
                <p class="mt-10 isContent">● Third party ad networks (e.g. Google)</p>
                <p class="mt-10 isContent">● Third parties for legal purposes (e.g. in response to legal process)</p>
                <p class="mt-10 isContent">● Others with your consent or at your direction</p>
                <p class="mt-10 isContent">Categories of Business & Commercial Purposes for Which We Use Your Personal Information</p>
                <p class="mt-10 isContent">The categories of personal information collected, as described above, are used for the following categories of purposes:</p>
                <p class="mt-10 isContent">● To provide, maintain, troubleshoot, and support our services.</p>
                <p class="mt-10 isContent">● For billing and payment purposes.</p>
                <p class="mt-10 isContent">● To communicate with users and prospective users.</p>
                <p class="mt-10 isContent">● To improve our services.</p>
                <p class="mt-10 isContent">● To develop new services.</p>
                <p class="mt-10 isContent">● To market and advertise our services.</p>
                <p class="mt-10 isContent">● To prevent harm or liability.</p>
                <p class="mt-10 isContent">● For legal compliance.</p>
                <p class="mt-10 isContent">More information about these categories, including examples of use cases, is available in Section 2 above.</p>
                <h3 class="mt-20 mb-10 titles">👉10. THIRD-PARTY SERVICES</h3>
                <p class="mt-10 isContent">You may access other third-party services while using the VPN services, for example by clicking on links to those third-party services from within the Services. We are not responsible for the privacy policies and/or practices of these third-party services, and we encourage you to carefully review their privacy policies.</p>
                <h3 class="mt-20 mb-10 titles">👉11. CHILDREN'S PRIVACY</h3> 
                <p class="mt-10 isContent">Our services are not intended for and may not be used by minors. In this context, minors are individuals under the age of 16 (or such lower age as may be applicable in a jurisdiction). We do not knowingly collect personal data from minors or allow them to use our services. We will only process the information of these minors to the extent permitted by law and with the consent of their guardians. We will implement relevant technical measures and make our best efforts to verify whether the processing of minors’ information has been approved by their guardians. We will try to delete relevant data as soon as possible if we are aware that we have collected the personal data of a minor without the prior consent of their certified guardians.</p>
        
                <h3 class="mt-20 mb-10 titles">👉12. Account security</h3> 
                <p class="mt-10 isContent">Account sharing: For the security of your account, non-customized accounts are limited to personal use. Please do not share your account with others. The loss caused by sharing to others will be borne by you.</p>
                <p class="mt-10 isContent">The number of devices refers to the total number of devices which can be used simultaneously. You can install Proxy Vpn client on multiple devices.</p>
                <h3 class="mt-20 mb-10 titles">👉13. ACCESS</h3> 
                <p class="mt-10 isContent">When you become one of our users for the VPN services, you may access certain information associated with you by emailing foxecomp@gmail.com. If you decide not to use our VPN services any more, any public activity on your Account prior to deletion may remain stored on our servers and may remain accessible to the public.</p>
                <p class="mt-10 isContent">To protect your privacy and security, we may also take reasonable steps to verify your identity before updating or removing your information. The information you provide us may be archived or stored periodically by us according to backup processes conducted in the ordinary course of business for disaster recovery purposes. Your ability to access and correct your information may be temporarily limited where access and correction could: inhibit our ability to comply with a legal obligation; inhibit our ability to investigate, make or defend legal claims; result in disclosure of personal information about a third party; or result in breach of a contract or disclosure of trade secrets or other proprietary business information belonging to us or a third party.</p>
                <p class="mt-10 isContent">Application data is generated when you become one of our VPN service users.For questions about the safe storage and deletion of application data, please feel free to contact us at chrishong20231010@gmail.com.</p>
                <h3 class="mt-20 mb-10 titles">👉14. General Data Protection Regulation (GDPR)</h3> 
                <p class="mt-10 isContent">CoolVPN Pro Team is committed to user privacy globally, and personal information under users' control and minimal collection of data are our existing practices. According to the requirements of the General Data Protection Regulation (GDPR) of the European Union (EU), we collect and process the data in a specific manner of users in EU outlined in this Privacy Policy on one of the following bases, depending on the circumstances:</p>
                <h3 class="mt-20 mb-10 titles">👉15. Privacy Policy Updates</h3> 
                <p class="mt-10 isContent">We may update this Privacy Policy from time to time in accordance with this section for reasons such as changes in laws, industry standards, and business practices. We will post updates to this page and update the “Last updated” date above. If we make updates that materially alter your privacy rights, we will also make reasonable efforts to provide you with advance notice, such as via email or through the services. If you disagree with such an update to this policy, you may cancel your services account. If you do not cancel your account before the date the update becomes effective, your continued use of our services will be subject to the updated Privacy Policy.</p>
                <h3 class="mt-20 mb-10 titles">👉16. Contact Us</h3> 
                <p class="mt-10 isContent">We expect this Privacy Policy to evolve over time and welcome feedback from our users about our privacy practices. f you have any questions or concerns about this Privacy Policy, please feel free to contact us at chrishong20231010@gmail.com.</p>
                    <p class="mt-10 isContent">Last updated: Dec 20, 2023</p>
    
            </div>
        </div>
    </section>
</template>
  
<script>
export default {
    // 组件逻辑
}
</script>

<style scoped>
header {
    position: relative;

    .containerTip {
        position: absolute;
        width: 100%;
        top: 50%;
        display: flex;
        justify-content: space-between;
        padding: 0 7%;

        .ins {
            color: #FFF;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: PingFang SC;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            text-decoration: auto;
        }

        .insLoge {
            font-size: 48px;
        }
    }
}

section {
    .isLink {
        color: #2a73f7;
    }

    .col-lg-6 {
        margin: 60px 0 80px 0;

        .h2ss {
            font-size: 64px;
            color: #333;
            font-weight: 500;
            text-align: center;
        }
    }

    .mb-10 {
        margin-bottom: 10px;
    }

    .mt-20 {
        margin-top: 20px;
    }

    p {
        text-indent: 25px;
    }

    .titles {
        color: #333;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: PingFang SC;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
    }

    .isContent {
        color: #404040;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: PingFang SC;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
    }
}
</style>